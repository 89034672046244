import React from "react"
import { Link } from "gatsby"

const AboutPage = () => {
    return (
        <div>
            <h1>Welcome to Mavic in the SKY...Page 2</h1>
            <Link to="/">Back to Home</Link> <br />
        </div>
    );
};

export default AboutPage;